<template>
    <v-container fluid style="padding-bottom:100px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
            <v-col cols="12">
                <v-card class="p-2">
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    clearable
                                    solo
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office"
                                    @change="(event) => updateDepartment(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    clearable
                                    solo
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    v-model="department"
                                    :items="departments"
                                    item-value="dept_id"
                                    item-text="dept_name"
                                    label="Department"
                                    @change="(event) => updateDivision(event)"
                                ></v-autocomplete>
                                <v-progress-linear
                                    :active="loading2"
                                    :indeterminate="loading2"
                                    absolute
                                    bottom
                                    color="deep-purple accent-4"
                                ></v-progress-linear>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    clearable
                                    solo
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    v-model="division"
                                    :items="divisions"
                                    item-value="division_id"
                                    item-text="division_name"
                                    label="Division"
                                    @change="(event) => updateEmployee(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    clearable
                                    solo
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    v-model="employee"
                                    :items="employees"
                                    item-value="idemployee"
                                    item-text="name"
                                    label="Employee"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date From"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date to"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <div class="d-flex">
                                    <v-btn class="border-12 mr-3 p-4" color="#f1f1f1" @click="clear()">Clear</v-btn>

                                    <v-btn
                                    class=" rounded-l p-4"
                                    color="info"
                                    elevation="2"
                                    large
                                    @click="search()"
                                    >Search</v-btn>

                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        solo
                                        class="border-12"
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search ID Employee"
                                        single-line
                                        @keyup.enter="search"
                                        hide-details
                                        style="max-width:30em"
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="purchases"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`header.idemployee`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.office_name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.dept_name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.division_name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.SUNDAY`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.C1`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.C2`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.C3`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.C4`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.C5`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.CUTI`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.HOLIDAY`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.FULLTIME`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.CUTI2HARI`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.IJIN2HARI`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.IJINTIDAKMASUK`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.IJINSAKIT`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.SAKITSURATDOKTER`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.TUGASLUAR`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.BELUMGABUNG`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title>Detail Attendance</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>Attendance
                                            <v-spacer></v-spacer>
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_po_items"
                                            :items="po_items"
                                            :options.sync="option_po_items"
                                            :loading="loading3"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItem_po_items"
                                            :page.sync="pagination_item"
                                            page-count="10"
                                            class="elevation-1"
                                        >
                                            <template v-slot:[`header.entry_date`]="{ header }">
                                                <button @click="sortByFuncDetail(header.value)">{{header.text}}</button>
                                                <i v-if="sortByDetail === 'desc'" aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                                <i v-else aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                            </template>
                                            <template v-slot:[`header.idemployee`]="{ header }">
                                                <button @click="sortByFuncDetail(header.value)">{{header.text}}</button>
                                                <i v-if="sortByDetail === 'desc'" aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                                <i v-else aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                            </template>
                                            <template v-slot:[`header.name`]="{ header }">
                                                <button @click="sortByFuncDetail(header.value)">{{header.text}}</button>
                                                <i v-if="sortByDetail === 'desc'" aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                                <i v-else aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                            </template>
                                            <template v-slot:[`header.office_name`]="{ header }">
                                                <button @click="sortByFuncDetail(header.value)">{{header.text}}</button>
                                                <i v-if="sortByDetail === 'desc'" aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                                <i v-else aria-hidden="true"  @click="sortByFuncDetail(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Starcon',
                disabled: false,
                href: '/admin/sms',
                },
                {
                text: 'HRIS',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Attendance Query',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            offices: [],
            office: '',
            departments: [],
            department: '',
            divisions: [],
            division: '',
            status: '',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            display: 'none',
            purchases: [],
            headers: [
                {
                    text: 'ID Employee',
                    align: 'start',
                    sortable: true,
                    value: 'idemployee',
                    class: 'primary--text blue lighten-5', sortable: false
                },
                { text: 'Name Employee', value: 'name',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Office', value: 'office_name',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Department', value: 'dept_name',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Division', value: 'division_name',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'FULL TIME', value: 'FULLTIME',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'SUNDAY', value: 'SUNDAY',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'C1', value: 'C1',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'C2', value: 'C2',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'C3', value: 'C3',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'C4', value: 'C4',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'C5', value: 'C5',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'CUTI', value: 'CUTI',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'CUTI 1\/2 HARI', value: 'CUTI2HARI',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN 1\/2 HARI', value: 'IJIN2HARI',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN TIDAK MASUK', value: 'IJINTIDAKMASUK',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN SAKIT', value: 'IJINSAKIT',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'HOLIDAY', value: 'HOLIDAY',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'SAKIT SURAT DOKTER', value: 'SAKITSURATDOKTER',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'TUGAS LUAR', value: 'TUGASLUAR',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'BELUM GABUNG', value: 'BELUMGABUNG',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Actions',  value: 'actions',
                    class: 'primary--text blue lighten-5', sortable: false },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            search_detail: '',
            header_po_items:[
                {
                    text: 'Entry Date',
                    align: 'start',
                    sortable: true,
                    value: 'entry_date',
                    class: 'primary--text blue lighten-5', sortable: false
                },
                { text: 'ID Employee', value: 'idemployee',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Name Employee', value: 'name',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Office', value: 'office_name',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Time IN', value: 'time_in',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Time Out', value: 'time_out',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Department', value: 'dept_name',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'Division', value: 'division_name',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'FULL TIME', value: 'FULL TIME',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'SUNDAY', value: 'SUNDAY',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'C1', value: 'C1',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'C2', value: 'C2',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'C3', value: 'C3',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'C4', value: 'C4',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'C5', value: 'C5',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'CUTI', value: 'CUTI',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'CUTI 1\/2 HARI', value: 'CUTI 1\/2 HARI',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN 1\/2 HARI', value: 'IJIN 1\/2 HARI',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN TIDAK MASUK', value: 'IJIN TIDAK MASUK',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'IJIN SAKIT', value: 'IJIN SAKIT',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'HOLIDAY', value: 'HOLIDAY',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'SAKIT SURAT DOKTER', value: 'SAKIT SURAT DOKTER',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'TUGAS LUAR', value: 'TUGAS LUAR',
                    class: 'primary--text blue lighten-5', sortable: false },
                { text: 'BELUM GABUNG', value: 'BELUM GABUNG',
                    class: 'primary--text blue lighten-5', sortable: false }
            ],
            po_items: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            data_var: {
                entity_id : 'STARCON',
                appl_id : 'WEBSTARCON'
            },
            user_approve1: '',
            user_approve2: '',
            dialogAgree: false,
            dialogAgree2: false,
            dialogDisagree2: false,
            dialogDisagree: false,
            pagination: 1,
            pagination_item: 1,
            loading_reject: false,
            employees: [],
            employee: '',
            sortBy: 'desc',
            sortName: '',
            sortByDetail: 'desc',
            sortNameDetail: '',
        }
    },
    mounted(){
        this.getOffice()
        // this.getEnvConf()
    },
    methods:{
        clear(){
            this.office = ''
            this.department = ''
            this.division = ''
            this.employee = ''
            this.year = ''
            this.search_detail = ''
            this.$store.state.text = ''
            this.date_from = ''
            this.date_to = ''
        },
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'APRVPO1') {
                        this.user_approve1 = res.data.data[i]['var_value']

                        if (this.user_approve1 != null) {
                            this.approve = 'approve1'

                            this.getPullData(1, 10)

                        }
                    }
                    
                    if (res.data.data[i]['var_id'] === 'APRVPO2') {
                        this.user_approve2 = res.data.data[i]['var_value']

                        if (this.user_approve2 != null) {
                            this.approve = 'approve2'

                            this.getPullData(1, 10)

                        }
                    }

                    if (this.approve != '') {
                        this.getPullData(1, 10)
                    }

                }

            })
        },
        search(){
            this.pagination = 1
            this.getPullData(1, 10, true)
        },
        search_temp(){
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
        },
        close() {
            this.dialog = false
        },
        showItem(item){
            console.log(item);
            this.po_item = item
            this.po_items = []
            this.dialog = true
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
        },
        sortByFunc(sortBy){
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.getPullData(1, 10)
        },
        sortByFuncDetail(sortBy){
            this.sortByDetail = this.sortByDetail === 'desc' ? 'asc' : 'desc';
            this.sortNameDetail = sortBy
            this.getPullDataDetail(1, 10, this.po_item)
        },
        async getPullDataDetail(page = 1, itemsPerPage = 10, item){
            this.loading3 = true

            this.po_items = []

            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/hris/attendance/show?search=${this.search_detail}&txtEmployee=${item.idemployee}&txtStart=${this.date_from ? this.date_from : ""}&txtEnd=${this.date_to ? this.date_to : ""}&sort=${this.sortByDetail ? this.sortByDetail : 'desc'}&sortName=${this.sortNameDetail ? this.sortNameDetail : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading3 = false
                this.po_items = res.data.data
                this.totalItem_po_items = res.data.total
                this.pagination_item = res.data.current_page
            })
        },
        async getPullData(page = 1, itemsPerPage = 10, search){
            this.loading = true

            this.purchases = []

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/starcon/hris/attendance?search=${this.$store.state.text}&txtOffID=${this.office ? this.office : ""}&txtDept=${this.department ? this.department : ""}&txtDiv=${this.division ? this.division : ""}&txtEmployee=${this.employee ? this.employee : ""}&txtStart=${start_date}&txtEnd=${end_date}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.display = 'block'
                this.loading = false
                this.purchases = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
            })
        },
        getOffice(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/starcon/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data
            });
        },
        updateDepartment(value){
            this.loading2 = true
            axios.get(`${process.env.VUE_APP_URL}/api/master/hris/department?entity_id=SMS`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.departments = res.data
                this.loading2 = false
            });
        },
        updateDivision(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/hris/division?entity_id=SMS&dept_id=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.divisions = res.data
            });
        },
        updateEmployee(value){
            axios.get(`${process.env.VUE_APP_URL}/api/master/hris/employee?entity_id=SMS&dept_id=${this.department ? this.department : ''}&division_id=${value ? value : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.employees = res.data
            });
        }
        
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === 'block') {
                    this.getPullData(page, itemsPerPage)
                }
            },
            deep: true,
        },
        option_po_items: {
            handler () {
                this.loading5 = true
                const { page, itemsPerPage } = this.option_po_items
                if (this.po_item && page >= 1) {
                    this.getPullDataDetail(page, itemsPerPage, this.po_item)
                }
            },
            deep: true,
        },
    }
}
</script>